import _Time from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import 'dayjs/locale/de';
import 'dayjs/locale/es';
import 'dayjs/locale/id';
import 'dayjs/locale/fr';
import 'dayjs/locale/th';
import 'dayjs/locale/zh-cn';
import Duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
_Time.extend(utc);
_Time.extend(Duration);
_Time.extend(isSameOrAfter);
_Time.extend(isSameOrBefore);
_Time.extend(RelativeTime);
_Time.locale(window.navigator.language.slice(0, 2));

export const Time = _Time;

export type Time = _Time.Dayjs;

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
