import {HostCode} from 'Utils/countryManager';

export const configs = {
  urls: createUrls(),
  firebaseAuth: initializeFirebaseAuth(),
  gaTrackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID!,
  chatting: initializeChatting(),
  deepLink: {
    base: process.env.REACT_APP_DEEP_LINK_BASE!,
    apn: process.env.REACT_APP_DEEP_LINK_APN!,
    ibi: process.env.REACT_APP_DEEP_LINK_IBI!,
    isi: process.env.REACT_APP_DEEP_LINK_ISI!,
    scheme: process.env.REACT_APP_DEEP_LINK_SCHEME!,
  },
  isProduction: process.env.REACT_APP_BUILD_MODE === 'production',
  defaultArtistMenu: '61026',
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
  },
};

function createUrls() {
  return {
    root: createUrlRoot(),
    domain: process.env.REACT_APP_DOMAIN!,
    base: process.env.REACT_APP_GOODS_BASE!,
    goods: process.env.REACT_APP_GOODS_GQL!,
    payment: process.env.REACT_APP_GOODS_PAYMENT!,
    togle: process.env.REACT_APP_TOGLE_SERVICE!,
    image: process.env.REACT_APP_URL_IMAGE!,
    defaultAvatarImage: process.env.REACT_APP_DEFAULT_AVATAR_IMAGE!,
    defaultCoverImage: process.env.REACT_APP_DEFAULT_COVER_IMAGE!,
    language: process.env.REACT_APP_URL_LANGUAGE!,
    company: process.env.REACT_APP_URL_COMPANY!,
    support: process.env.REACT_APP_URL_SUPPORT!,
    slackGoodsLogin: process.env.REACT_APP_URL_SLACK_GOODS_LOGIN!,
    slackGoodsManage: process.env.REACT_APP_URL_SLACK_GOODS_MANAGE!,
    slackGoodsStatus: process.env.REACT_APP_URL_SLACK_GOODS_STATUS!,
    slackGoodsEvent: process.env.REACT_APP_URL_SLACK_GOODS_EVENT!,
    googleChatBotUrl: process.env.REACT_APP_GOOGLE_CHAT_BOT_URL!,
    kakaoApiKey: process.env.REACT_APP_KAKAO_API_KEY!,
    chartSendData: process.env.REACT_APP_URL_CHART_SEND_DATA!,
    chartSendAccessKey: process.env.REACT_APP_CHART_SEND_DATA_ACCESS_KEY!,

    urlRoot: process.env.REACT_APP_URL_ROOT,
    rootEn: process.env.REACT_APP_URL_ROOT_WWW,
    rootJp: process.env.REACT_APP_URL_ROOT_JP,
    rootKr: process.env.REACT_APP_URL_ROOT_KR,
    rootEs: process.env.REACT_APP_URL_ROOT_ES,
    rootCn: process.env.REACT_APP_URL_ROOT_CN,
    rootTh: process.env.REACT_APP_URL_ROOT_TH,
    rootDe: process.env.REACT_APP_URL_ROOT_DE,
    rootId: process.env.REACT_APP_URL_ROOT_ID,
    rootFr: process.env.REACT_APP_URL_ROOT_FR,
    rootBr: process.env.REACT_APP_URL_ROOT_BR,
    rootAu: process.env.REACT_APP_URL_ROOT_AU,
    rootCa: process.env.REACT_APP_URL_ROOT_CA,
    rootHk: process.env.REACT_APP_URL_ROOT_HK,
    rootMy: process.env.REACT_APP_URL_ROOT_MY,
    rootNz: process.env.REACT_APP_URL_ROOT_NZ,
    rootPh: process.env.REACT_APP_URL_ROOT_PH,
    rootSg: process.env.REACT_APP_URL_ROOT_SG,
    rootTw: process.env.REACT_APP_URL_ROOT_TW,
    rootGb: process.env.REACT_APP_URL_ROOT_GB,
    rootVn: process.env.REACT_APP_URL_ROOT_VN,
    rootRu: process.env.REACT_APP_URL_ROOT_RU,

    naverShipLink: 'https://search.naver.com/search.naver?query=',
    ship24Link: 'https://www.ship24.com/tracking?p=',
    twitterLink: 'https://twitter.com/ArtistsCard',
    instagramLink: 'https://www.instagram.com/artistscard_official',
    youtubeLink: 'https://www.youtube.com/channel/UCYiFAPRYSINuOCCBwt_r7GA?sub_confirmation=1',
    unboxingLink: 'https://www.youtube.com/c/Artists%E2%80%99CardUNBOXING?sub_confirmation=1',
    appDownloadLink: 'https://app.coverlala.com/qr',
    hanteoChartLink: 'https://hanteochart.com/family/search',
    circleChartLink: 'https://circlechart.kr/',
  };
}

function initializeFirebaseAuth() {
  return {
    apiKey: process.env.REACT_APP_FIREBASE_AUTH_API_KEY!,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_AUTH_DOMAIN!,
    databaseURL: process.env.REACT_APP_FIREBASE_AUTH_DATABASE_URL!,
    projectId: process.env.REACT_APP_FIREBASE_AUTH_PROJECT_ID!,
    storageBucket: process.env.REACT_APP_FIREBASE_AUTH_STORAGE_BUCKET!,
    messagingSenderId: process.env.REACT_APP_FIREBASE_AUTH_MESSAGING_SENDER_ID!,
    appId: process.env.REACT_APP_FIREBASE_AUTH_APP_ID!,
    measurementId: process.env.REACT_APP_FIREBASE_AUTH_MEASUREMENT_ID!,
  };
}

function initializeChatting() {
  return {
    url: process.env.REACT_APP_CHATTING_URL!,
    region: process.env.REACT_APP_CHATTING_REGION!,
    apiKey: process.env.REACT_APP_CHATTING_API_KEY!,
  };
}

function createUrlRoot() {
  const base = window.location.hostname.split('.')[0];
  switch (base) {
    case HostCode.Www:
      return process.env.REACT_APP_URL_ROOT_WWW!;
    case HostCode.Jp:
      return process.env.REACT_APP_URL_ROOT_JP!;
    case HostCode.Kr:
      return process.env.REACT_APP_URL_ROOT_KR!;
    case HostCode.Cn:
      return process.env.REACT_APP_URL_ROOT_CN!;
    case HostCode.De:
      return process.env.REACT_APP_URL_ROOT_DE!;
    case HostCode.Es:
      return process.env.REACT_APP_URL_ROOT_ES!;
    case HostCode.Id:
      return process.env.REACT_APP_URL_ROOT_ID!;
    case HostCode.Th:
      return process.env.REACT_APP_URL_ROOT_TH!;

    case HostCode.Fr:
      return process.env.REACT_APP_URL_ROOT_FR!;
    case HostCode.Br:
      return process.env.REACT_APP_URL_ROOT_BR!;
    case HostCode.Au:
      return process.env.REACT_APP_URL_ROOT_AU!;
    case HostCode.Ca:
      return process.env.REACT_APP_URL_ROOT_CA!;
    case HostCode.Hk:
      return process.env.REACT_APP_URL_ROOT_HK!;
    case HostCode.My:
      return process.env.REACT_APP_URL_ROOT_MY!;
    case HostCode.Nz:
      return process.env.REACT_APP_URL_ROOT_NZ!;
    case HostCode.Ph:
      return process.env.REACT_APP_URL_ROOT_PH!;
    case HostCode.Sg:
      return process.env.REACT_APP_URL_ROOT_SG!;
    case HostCode.Tw:
      return process.env.REACT_APP_URL_ROOT_TW!;
    case HostCode.Gb:
      return process.env.REACT_APP_URL_ROOT_GB!;
    case HostCode.Vn:
      return process.env.REACT_APP_URL_ROOT_VN!;
    case HostCode.Ru:
      return process.env.REACT_APP_URL_ROOT_RU!;

    default:
      return process.env.REACT_APP_URL_ROOT!;
  }
}
