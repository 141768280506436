import ReactDOM from 'react-dom';
import {reportWebVitals} from './reportWebVitals';
import './Styles';
import {Sentry} from 'Lib/sentry';

declare const Root: HTMLDivElement;
Sentry.init();
entry();

async function entry(): Promise<void> {
  const {App} = await import('./App');
  ReactDOM.render(<App />, Root);

  reportWebVitals();
}
