import {CountryCode} from 'GraphQL/Schema/Server';

export enum LanguageCode {
  Ko = 'ko',
  En = 'en',
  Ja = 'ja',
  Xx = 'xx',
  Es = 'es', // 스페인
  ZhChs = 'zh-chs', // 중국 간체
  ZhCht = 'zh-cht', // 중국 번체
  Th = 'th', // 태국
  De = 'de', // 독일
  Id = 'id', // 인도
  Oo = 'oo',
  Fr = 'fr',
  Br = 'br',
  My = 'my',
  Vn = 'vn',
  Ru = 'ru',
}

export enum SeoLanguageCode {
  Ko = 'ko',
  En = 'en',
  Ja = 'ja',
  Es = 'es',
  Id = 'id',
  Th = 'th',
  De = 'de',
  Fr = 'fr',
  Br = 'pt',
  Au = 'en-au',
  Ca = 'en-ca',
  Hk = 'zh-Hant',
  My = 'ms',
  Nz = 'en-nz',
  Ph = 'en-ph',
  Sg = 'en-sg',
  Tw = 'zh-Hant',
  Gb = 'en-gb',
  Vn = 'vi',
  Default = 'x-default',
}
export const NEED_IDENTIFICATION_COUNTRY = [CountryCode.Bra];
