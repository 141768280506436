import {StatusOfCommodity} from 'GraphQL/Schema/Server';
import {Time} from 'Lib/time';

export const formatDate = (time: Date | Time): string => Time(time).local().format('YYYY-MM-DD');
export const formatLocalDateTime = (time: Date | Time): string => Time(time).local().format('YYYY-MM-DD HH:mm:ss');
export const formatDateYYMMDD = (time: Date | Time): string => Time(time).local().format('YY.MM.DD');
export const formatUtcDateForGql = (time: Date | Time): string => Time(time).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
export const secondsToTime = (time: number) => {
  const h = Math.floor(time / 3600);
  const m = Math.floor((time - h * 3600) / 60);
  const s = time - h * 3600 - m * 60;
  const hours = `0${h}`.slice(-2);
  const minutes = `0${m}`.slice(-2);
  const seconds = `0${s}`.slice(-2);
  return h > 0 ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
};

export const formatStructuredData = (arg: any) => {
  return JSON.stringify({
    '@context': 'https://schema.org',
    ...arg,
  });
};

export const formatProductStatusToText = (status: StatusOfCommodity) => {
  switch (status) {
    case StatusOfCommodity.Prepare:
      return '판매 대기';
    case StatusOfCommodity.Ready:
      return '판매중';
    case StatusOfCommodity.InStore:
      return '나에 배송';
    case StatusOfCommodity.BeforeShipping:
      return '배송전';
    case StatusOfCommodity.Preparing:
      return '배송준비중';
    case StatusOfCommodity.Shipping:
      return '배송중';
    case StatusOfCommodity.Arrive:
      return '배송 완료';
    case StatusOfCommodity.Confirm:
      return '판매 완료';
    case StatusOfCommodity.Event:
      return '이벤트용';
  }
};

export const formatNumberToPriceFormat = (price: number) => new Intl.NumberFormat().format(price);
export const formatOnlyNumber = (value: string): string => value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
export const formatStringWithComma = (value: string): string => value.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
export const formatStringWithUnComma = (value: string): string => value.replace(/[^\d]+/g, '');
export const formatOnlyNumberWithComma = (value: string): string => formatStringWithComma(formatStringWithUnComma(value));
export const formatOnlyNumberWithUncomma = (value: string): string => formatOnlyNumber(formatStringWithUnComma(value));
export const formatStringToPhoneNumber = (value: string): string => {
  let target = value;
  if (target.startsWith('+')) {
    target = `0${target.slice(-10)}`;
  }
  return target.replace(/[^0-9]/, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
};
export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

const KG = 1000;

export const formatWeightUnit = (weight: number) => {
  if (weight < 1000) {
    return `${weight} g`;
  }
  return `${weight / KG} kg`;
};

export const formatFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
