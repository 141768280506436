import {IconName} from 'App/Atomics/Icon';
import {configs} from 'Configs';
import {LanguageCode} from 'Constants/LanguageCode';
import {CountryCode, CurrencyType} from 'GraphQL/Schema/Server';
import {formatFirstLetter} from 'Utils/format';

export enum HostCode {
  Kr = 'kr', // 한국
  Jp = 'jp', // 일본
  Www = 'www', // 미국
  Es = 'es', // 스페인
  Cn = 'cn', // 중국
  Th = 'th', // 태국
  De = 'de', // 독일
  Id = 'id', // 인도

  Sg = 'sg', // 싱가포르
  Ph = 'ph', // 필리핀
  Nz = 'nz', // 뉴질랜드
  Ca = 'ca', // 캐나다
  Au = 'au', // 오스트레일리아
  Fr = 'fr', // 프랑스
  Br = 'br', // 브라질
  Hk = 'hk', // 홍콩
  My = 'my', // 말레이시아
  Tw = 'tw', // 대만
  Gb = 'gb', // 영국
  Vn = 'vn', // 베트남
  Ru = 'ru', // 러시아

  DKr = '', // 기본 한국
}

export enum CurrencySymbol {
  Krw = '₩', // 한국
  Usd = '$', // 미국
  Jpy = '¥', // 엔화 [중국과 동일]
  Eur = '€', // 스페인, 독일, 프랑스
  Cny = '¥', // 위안 [일본과 동일]
  Thb = '฿', // 태국
  Idr = 'Rp', // 인도,
  Brl = 'R$', // 헤알 브라질
  Aud = 'A$', //오스트에일리아 달러,
  Cad = 'C$', // 캐나다 달러
  Hkd = 'HK$', //홍콩 달러
  Myr = 'RM', // 말레이시아 링기트
  Nzd = 'NZ$', //뉴질렌드 달러
  Php = '₱', //필리핀 페소
  Sgd = 'S$', // 싱가포르 달러
  Twd = 'NT$', //타이완 신타이비
  Gbp = '£', // 영국 파운드스털링
  Vnd = '₫', // 베트남 동
  Rub = '₽', // 러시아 루블
}

export const EnCountryName: Record<string, string> = {
  [CountryCode.Usa]: 'United State',
  [CountryCode.Jpn]: 'Japan',
  [CountryCode.Kor]: 'Korea',
  [CountryCode.Aus]: 'Australia',
  [CountryCode.Bra]: 'Brazil',
  [CountryCode.Can]: 'Canada',
  [CountryCode.Chn]: 'China',
  [CountryCode.Fra]: 'France',
  [CountryCode.Deu]: 'Germany',
  [CountryCode.Hkg]: 'Hong Kong',
  [CountryCode.Idn]: 'Indonesia',
  [CountryCode.Mys]: 'Malaysia',
  [CountryCode.Nzl]: 'New Zealand',
  [CountryCode.Phl]: 'Philippines',
  [CountryCode.Rus]: 'Russia',
  [CountryCode.Sgp]: 'Singapore',
  [CountryCode.Esp]: 'Spain',
  [CountryCode.Twn]: 'Taiwan',
  [CountryCode.Tha]: 'Thailand',
  [CountryCode.Gbr]: 'The United kingdom',
  [CountryCode.Vnm]: 'Vietnams',
  [CountryCode.Bel]: 'Belgium',
  [CountryCode.Khm]: 'Cambodia',
  [CountryCode.Lao]: "LAO PEOPLE'S DEM REP",
  [CountryCode.Mng]: 'Mongolia',
  [CountryCode.Mmr]: 'Myanmar',
  [CountryCode.Bgd]: 'Bangladesh',
  [CountryCode.Ind]: 'India',
  [CountryCode.Mdv]: 'Maldives',
  [CountryCode.Lka]: 'Sri Lanka',
  [CountryCode.Aut]: 'Austria',
  [CountryCode.Aze]: 'Azerbaijan',
  [CountryCode.Bgr]: 'Bulgaria(Rep)',
  [CountryCode.Cyp]: 'Cyprus',
  [CountryCode.Cze]: 'Czech(Rep)',
  [CountryCode.Dnk]: 'Denmark',
  [CountryCode.Fin]: 'Finland',
  [CountryCode.Geo]: 'Georgia',
  [CountryCode.Grc]: 'Greece',
  [CountryCode.Hun]: 'Hungary(Rep)',
  [CountryCode.Irl]: 'Ireland',
  [CountryCode.Irn]: 'Iran',
  [CountryCode.Isr]: 'Israel',
  [CountryCode.Jor]: 'Jordan',
  [CountryCode.Kaz]: 'Kazakhstan',
  [CountryCode.Lux]: 'Luxembourg',
  [CountryCode.Nld]: 'Netherlands',
  [CountryCode.Nor]: 'Norway',
  [CountryCode.Omn]: 'Oman',
  [CountryCode.Pol]: 'Poland',
  [CountryCode.Prt]: 'Portugal',
  [CountryCode.Qat]: 'Qatar',
  [CountryCode.Rou]: 'Romania',
  [CountryCode.Sau]: 'Saudi Arabia',
  [CountryCode.Svn]: 'Slovenia',
  [CountryCode.Swe]: 'Sweden',
  [CountryCode.Che]: 'Switzerland',
  [CountryCode.Tur]: 'Turkey',
  [CountryCode.Ukr]: 'Ukraine',
  [CountryCode.Irl]: 'Ireland',
  [CountryCode.Are]: 'United Arab Emirates',
  [CountryCode.Uzb]: 'Uzbekistan',
  [CountryCode.Chl]: 'Chile',
  [CountryCode.Egy]: 'Egypt',
  [CountryCode.Eth]: 'Ethiopia',
  [CountryCode.Ken]: 'Kenya',
  [CountryCode.Mex]: 'Mexico',
  [CountryCode.Nga]: 'Nigeria',
  [CountryCode.Tza]: 'Tanzania United Rep',
  [CountryCode.Tun]: 'Tunisia',
};

export const CountryPhoneNum: Record<string, string> = {
  [CountryCode.Kor]: '82',
  [CountryCode.Usa]: '1',
  [CountryCode.Jpn]: '81',
  [CountryCode.Chn]: '86',
  [CountryCode.Deu]: '49',
  [CountryCode.Esp]: '34',
  [CountryCode.Idn]: '62',
  [CountryCode.Tha]: '66',
  [CountryCode.Fra]: '33',
  [CountryCode.Bra]: '55',
  [CountryCode.Aus]: '61',
  [CountryCode.Can]: '1',
  [CountryCode.Hkg]: '852',
  [CountryCode.Mys]: '60',
  [CountryCode.Nzl]: '64',
  [CountryCode.Phl]: '63',
  [CountryCode.Sgp]: '65',
  [CountryCode.Twn]: '886',
  [CountryCode.Rus]: '7',
  [CountryCode.Gbr]: '44',
  [CountryCode.Vnm]: '84',
  [CountryCode.Bel]: '32',
  [CountryCode.Khm]: '855',
  [CountryCode.Lao]: '856',
  [CountryCode.Mng]: '976',
  [CountryCode.Mmr]: '95',
  [CountryCode.Bgd]: '880',
  [CountryCode.Ind]: '91', // (인도)
  [CountryCode.Mdv]: '960', // (몰디브)
  [CountryCode.Lka]: '94', // (스리랑카)
  [CountryCode.Aut]: '43', // (오스트리아)
  [CountryCode.Aze]: '994', // (아제르바이잔)
  [CountryCode.Bgr]: '359', // (불가리아)
  [CountryCode.Cyp]: '357', // (사이프러스)
  [CountryCode.Cze]: '420', //  (체코)
  [CountryCode.Dnk]: '45', // (덴마크)
  [CountryCode.Fin]: '358', // (핀란드)
  [CountryCode.Geo]: '995', // (조지아)
  [CountryCode.Grc]: '30', // (그리스)
  [CountryCode.Hun]: '36', // (헝가리)
  [CountryCode.Irn]: '98', // (이란)
  [CountryCode.Irl]: '353', // (아일랜드(에이레))
  [CountryCode.Isr]: '972', // (이스라엘)
  [CountryCode.Jor]: '962', // (요르단)
  [CountryCode.Kaz]: '7', // (카자흐스탄)
  [CountryCode.Lux]: '352', // (룩셈부르크)
  [CountryCode.Nld]: '31', // (네덜란드(네델란드))
  [CountryCode.Nor]: '47', // (노르웨이)
  [CountryCode.Omn]: '968', // (오만)
  [CountryCode.Pol]: '48', // (폴란드)
  [CountryCode.Prt]: '351', // (포르투갈)
  [CountryCode.Qat]: '974', // (카타르)
  [CountryCode.Rou]: '40', // (루마니아)
  [CountryCode.Sau]: '966', //  (사우디아라비아)
  [CountryCode.Svn]: '386', // (슬로베니아)
  [CountryCode.Swe]: '46', // (스웨덴)
  [CountryCode.Che]: '41', // (스위스)
  [CountryCode.Tur]: '90', // (터키)
  [CountryCode.Ukr]: '380', // (우크라이나)
  [CountryCode.Are]: '971', //  (아랍에미리트연합국)
  [CountryCode.Uzb]: '998', // (우즈베키스탄)
  [CountryCode.Chl]: '56', // (칠레)
  [CountryCode.Egy]: '20', // (이집트)
  [CountryCode.Eth]: '251', // (에티오피아)
  [CountryCode.Ken]: '254', // (케냐)
  [CountryCode.Mex]: '52', // (멕시코)
  [CountryCode.Nga]: '234', // (나이지리아)
  [CountryCode.Tza]: '255', // (탄자니아)
  [CountryCode.Tun]: '216', // (튀니지)
};

const DEFAULT_COUNTRY_CODE = HostCode.DKr;

const isHost = (base: string) => base in HostCode;

export const currentHost = (): HostCode => {
  const base = window.location.host.split('.')[0];

  const hostKey = formatFirstLetter(base);

  if (!isHost(hostKey)) return DEFAULT_COUNTRY_CODE;

  return HostCode[hostKey as keyof typeof HostCode];
};

type Response = {
  readonly currencyType: CurrencyType;
  readonly defaultCurrencyType: CurrencyType;
  readonly currencySymbol: CurrencySymbol;
  readonly countryCode: CountryCode;
  readonly host: HostCode;
};

//사용가능 화폐: 원화, 달러, 엔화, [위안] [defaultCurrencyType]
export const countryManager = (): Response => {
  const country = currentHost();

  switch (country) {
    case HostCode.Kr: //한국
      return {
        currencyType: CurrencyType.Krw,
        defaultCurrencyType: getDefaultCurrencyType(CurrencyType.Krw),
        currencySymbol: CurrencySymbol.Krw,
        countryCode: CountryCode.Kor,
        host: HostCode.Kr,
      };
    case HostCode.Jp: //일본
      return {
        currencyType: CurrencyType.Jpy,
        defaultCurrencyType: getDefaultCurrencyType(CurrencyType.Jpy),
        currencySymbol: CurrencySymbol.Jpy,
        countryCode: CountryCode.Jpn,
        host: HostCode.Jp,
      };
    case HostCode.Www: //미국
      return {
        currencyType: CurrencyType.Usd,
        defaultCurrencyType: getDefaultCurrencyType(CurrencyType.Usd),
        currencySymbol: CurrencySymbol.Usd,
        countryCode: CountryCode.Usa,
        host: HostCode.Www,
      };
    case HostCode.Cn: // 중국
      return {
        currencyType: CurrencyType.Cny,
        defaultCurrencyType: getDefaultCurrencyType(CurrencyType.Cny),
        currencySymbol: CurrencySymbol.Cny,
        countryCode: CountryCode.Chn,
        host: HostCode.Cn,
      };
    case HostCode.Es: // 스페인
      return {
        currencyType: CurrencyType.Eur,
        defaultCurrencyType: getDefaultCurrencyType(CurrencyType.Eur),
        currencySymbol: CurrencySymbol.Eur,
        countryCode: CountryCode.Esp,
        host: HostCode.Es,
      };
    case HostCode.Id: // 인도네시아
      return {
        currencyType: CurrencyType.Idr,
        defaultCurrencyType: getDefaultCurrencyType(CurrencyType.Idr),
        currencySymbol: CurrencySymbol.Idr,
        countryCode: CountryCode.Idn,
        host: HostCode.Id,
      };
    case HostCode.Th: // 태국
      return {
        currencyType: CurrencyType.Thb,
        defaultCurrencyType: getDefaultCurrencyType(CurrencyType.Thb),
        currencySymbol: CurrencySymbol.Thb,
        countryCode: CountryCode.Tha,
        host: HostCode.Th,
      };
    case HostCode.De: // 독일
      return {
        currencyType: CurrencyType.Eur,
        defaultCurrencyType: getDefaultCurrencyType(CurrencyType.Eur),
        currencySymbol: CurrencySymbol.Eur,
        countryCode: CountryCode.Deu,
        host: HostCode.De,
      };
    case HostCode.Fr: // 프랑스
      return {
        currencyType: CurrencyType.Eur,
        defaultCurrencyType: getDefaultCurrencyType(CurrencyType.Eur),
        currencySymbol: CurrencySymbol.Eur,
        countryCode: CountryCode.Fra,
        host: HostCode.Fr,
      };
    case HostCode.Br: // 브라질
      return {
        currencyType: CurrencyType.Brl,
        defaultCurrencyType: getDefaultCurrencyType(CurrencyType.Brl),
        currencySymbol: CurrencySymbol.Brl,
        countryCode: CountryCode.Bra,
        host: HostCode.Br,
      };
    case HostCode.Au: // 호주
      return {
        currencyType: CurrencyType.Aud,
        defaultCurrencyType: getDefaultCurrencyType(CurrencyType.Aud),
        currencySymbol: CurrencySymbol.Aud,
        countryCode: CountryCode.Aus,
        host: HostCode.Au,
      };
    case HostCode.Ca: // 캐나다
      return {
        currencyType: CurrencyType.Cad,
        defaultCurrencyType: getDefaultCurrencyType(CurrencyType.Cad),
        currencySymbol: CurrencySymbol.Cad,
        countryCode: CountryCode.Can,
        host: HostCode.Ca,
      };
    case HostCode.Hk: // 홍콩
      return {
        currencyType: CurrencyType.Hkd,
        defaultCurrencyType: getDefaultCurrencyType(CurrencyType.Hkd),
        currencySymbol: CurrencySymbol.Hkd,
        countryCode: CountryCode.Hkg,
        host: HostCode.Hk,
      };
    case HostCode.My: // 말레이시아
      return {
        currencyType: CurrencyType.Myr,
        defaultCurrencyType: getDefaultCurrencyType(CurrencyType.Myr),
        currencySymbol: CurrencySymbol.Myr,
        countryCode: CountryCode.Mys,
        host: HostCode.My,
      };
    case HostCode.Nz: // 뉴질렌드
      return {
        currencyType: CurrencyType.Nzd,
        defaultCurrencyType: getDefaultCurrencyType(CurrencyType.Nzd),
        currencySymbol: CurrencySymbol.Nzd,
        countryCode: CountryCode.Nzl,
        host: HostCode.Nz,
      };
    case HostCode.Ph: // 필리핀
      return {
        currencyType: CurrencyType.Php,
        defaultCurrencyType: getDefaultCurrencyType(CurrencyType.Php),
        currencySymbol: CurrencySymbol.Php,
        countryCode: CountryCode.Phl,
        host: HostCode.Ph,
      };
    case HostCode.Sg: // 싱가포르
      return {
        currencyType: CurrencyType.Sgd,
        defaultCurrencyType: getDefaultCurrencyType(CurrencyType.Sgd),
        currencySymbol: CurrencySymbol.Sgd,
        countryCode: CountryCode.Sgp,
        host: HostCode.Sg,
      };
    case HostCode.Tw: // 대만
      return {
        currencyType: CurrencyType.Twd,
        defaultCurrencyType: getDefaultCurrencyType(CurrencyType.Twd),
        currencySymbol: CurrencySymbol.Twd,
        countryCode: CountryCode.Twn,
        host: HostCode.Tw,
      };
    case HostCode.Gb: // 영국
      return {
        currencyType: CurrencyType.Gbp,
        defaultCurrencyType: getDefaultCurrencyType(CurrencyType.Gbp),
        currencySymbol: CurrencySymbol.Gbp,
        countryCode: CountryCode.Gbr,
        host: HostCode.Gb,
      };
    case HostCode.Vn: // 베트남
      return {
        currencyType: CurrencyType.Vnd,
        defaultCurrencyType: getDefaultCurrencyType(CurrencyType.Vnd),
        currencySymbol: CurrencySymbol.Vnd,
        countryCode: CountryCode.Vnm,
        host: HostCode.Vn,
      };
    case HostCode.Ru: // 러시아
      return {
        currencyType: CurrencyType.Rub,
        defaultCurrencyType: getDefaultCurrencyType(CurrencyType.Rub),
        currencySymbol: CurrencySymbol.Rub,
        countryCode: CountryCode.Rus,
        host: HostCode.Ru,
      };

    default:
      return {
        currencyType: CurrencyType.Krw,
        defaultCurrencyType: getDefaultCurrencyType(CurrencyType.Krw),
        currencySymbol: CurrencySymbol.Krw,
        countryCode: CountryCode.Kor,
        host: HostCode.DKr,
      };
  }
};

export const availableCurrencies = [CurrencyType.Krw, CurrencyType.Usd, CurrencyType.Jpy];
export const getDefaultCurrencyType = (currencyType: CurrencyType): CurrencyType => {
  if (!availableCurrencies.includes(currencyType)) {
    return CurrencyType.Usd;
  }

  return currencyType;
};

export const getCurrencySymbol = (currency: CurrencyType) => {
  switch (currency) {
    case CurrencyType.Krw:
      return CurrencySymbol.Krw;
    case CurrencyType.Jpy:
      return CurrencySymbol.Jpy;
    case CurrencyType.Usd:
      return CurrencySymbol.Usd;
    case CurrencyType.Cny:
      return CurrencySymbol.Cny;
    case CurrencyType.Eur:
      return CurrencySymbol.Eur;
    case CurrencyType.Idr:
      return CurrencySymbol.Idr;
    case CurrencyType.Thb:
      return CurrencySymbol.Thb;

    case CurrencyType.Brl:
      return CurrencySymbol.Brl;

    case CurrencyType.Aud:
      return CurrencySymbol.Aud;
    case CurrencyType.Cad:
      return CurrencySymbol.Cad;
    case CurrencyType.Hkd:
      return CurrencySymbol.Hkd;
    case CurrencyType.Myr:
      return CurrencySymbol.Myr;
    case CurrencyType.Nzd:
      return CurrencySymbol.Nzd;
    case CurrencyType.Php:
      return CurrencySymbol.Php;
    case CurrencyType.Sgd:
      return CurrencySymbol.Sgd;
    case CurrencyType.Twd:
      return CurrencySymbol.Twd;
    case CurrencyType.Gbp:
      return CurrencySymbol.Gbp;
    case CurrencyType.Vnd:
      return CurrencySymbol.Vnd;
    case CurrencyType.Rub:
      return CurrencySymbol.Rub;

    default:
      return CurrencySymbol.Krw;
  }
};

type CurrencyInfo = {
  readonly name: string;
  readonly languageName: string;
  readonly icon: IconName;
  readonly currency: CurrencyType;
  readonly countryCode2: string;
};

export const ALLOW_COUNTRY_CODE = [
  CountryCode.Kor,
  CountryCode.Usa,
  CountryCode.Jpn,
  CountryCode.Chn,
  CountryCode.Esp,
  CountryCode.Deu,
  CountryCode.Idn,
  CountryCode.Tha,
  CountryCode.Fra,
  CountryCode.Bra,
  CountryCode.Aus,
  CountryCode.Can,
  CountryCode.Hkg,
  CountryCode.Mys,
  CountryCode.Nzl,
  CountryCode.Phl,
  CountryCode.Sgp,
  CountryCode.Twn,
  CountryCode.Gbr,
  CountryCode.Vnm,
  CountryCode.Rus,
];

export const getCurrencyInfo = (countryCode: CountryCode): CurrencyInfo => {
  switch (countryCode) {
    case CountryCode.Kor:
      return {name: '대한민국', languageName: `한국어`, icon: 'logo-korea', currency: CurrencyType.Krw, countryCode2: 'KR'};
    case CountryCode.Usa:
      return {name: 'USA', languageName: `English`, icon: 'logo-usa', currency: CurrencyType.Usd, countryCode2: 'US'};
    case CountryCode.Jpn:
      return {name: '日本', languageName: `日本語`, icon: 'logo-japan', currency: CurrencyType.Jpy, countryCode2: 'JP'};
    case CountryCode.Chn:
      return {name: '中国', languageName: `中國語`, icon: 'logo-chn', currency: CurrencyType.Cny, countryCode2: 'CN'};
    case CountryCode.Esp:
      return {name: 'España', languageName: `español`, icon: 'logo-spain', currency: CurrencyType.Eur, countryCode2: 'ES'};
    case CountryCode.Deu:
      return {name: 'Deutschland', languageName: `Deutsch`, icon: 'logo-germany', currency: CurrencyType.Eur, countryCode2: 'DE'};
    case CountryCode.Idn:
      return {name: 'Indonesia', languageName: `Bahasa Indonesia`, icon: 'logo-indonesia', currency: CurrencyType.Idr, countryCode2: 'ID'};
    case CountryCode.Tha:
      return {name: 'ประเทศไทย', languageName: `ภาษาไทย`, icon: 'logo-thailand', currency: CurrencyType.Thb, countryCode2: 'TH'};
    case CountryCode.Mys:
      return {name: 'Malaysia', languageName: `Bahasa Melayu moden`, icon: 'logo-mys', currency: CurrencyType.Myr, countryCode2: 'MY'};
    case CountryCode.Phl:
      return {name: 'Pilipinas', languageName: `Filipino`, icon: 'logo-phl', currency: CurrencyType.Php, countryCode2: 'PH'};
    case CountryCode.Fra:
      return {name: 'La France', languageName: `Français`, icon: 'logo-fra', currency: CurrencyType.Eur, countryCode2: 'FR'};
    case CountryCode.Vnm:
      return {name: 'Việt Nam', languageName: `Tiếng Việt`, icon: 'logo-vnm', currency: CurrencyType.Vnd, countryCode2: 'VN'};
    case CountryCode.Rus:
      return {name: 'Россия', languageName: `русский язык`, icon: 'logo-rus', currency: CurrencyType.Rub, countryCode2: 'RU'};
    case CountryCode.Sgp:
      return {name: 'Singapore', languageName: `English`, icon: 'logo-sgp', currency: CurrencyType.Sgd, countryCode2: 'SG'}; // 싱가포르
    case CountryCode.Nzl:
      return {name: 'New Zealand', languageName: `English`, icon: 'logo-nzl', currency: CurrencyType.Nzd, countryCode2: 'NZ'}; // 뉴질랜드
    case CountryCode.Can:
      return {name: 'Canada', languageName: `English`, icon: 'logo-can', currency: CurrencyType.Cad, countryCode2: 'CA'}; // 캐나다
    //gpt
    case CountryCode.Bra:
      return {name: 'brasil', languageName: `brasil`, icon: 'logo-bra', currency: CurrencyType.Brl, countryCode2: 'BR'};
    case CountryCode.Gbr:
      return {name: 'United Kingdom', languageName: `English`, icon: 'logo-gbr', currency: CurrencyType.Gbp, countryCode2: 'GB'};
    case CountryCode.Aus:
      return {name: 'Australia', languageName: `English`, icon: 'logo-aus', currency: CurrencyType.Aud, countryCode2: 'AU'};
    case CountryCode.Hkg:
      return {name: '香港', languageName: `繁體中文`, icon: 'logo-hkg', currency: CurrencyType.Hkd, countryCode2: 'HK'};
    case CountryCode.Twn:
      return {name: '台灣', languageName: `繁體中文`, icon: 'logo-twn', currency: CurrencyType.Twd, countryCode2: 'TW'};
    case CountryCode.Bel:
      return {name: 'Belgium', languageName: `Belgium`, icon: 'logo-bel', currency: CurrencyType.Eur, countryCode2: 'BE'};
    case CountryCode.Khm:
      return {name: 'Cambodia', languageName: 'ភាសាខ្មែរ', icon: 'logo-khm', currency: CurrencyType.Usd, countryCode2: 'KH'}; //Khr;
    case CountryCode.Lao:
      return {name: "LAO PEOPLE'S DEM REP", languageName: 'ພາສາລາວ', icon: 'logo-lao', currency: CurrencyType.Usd, countryCode2: 'LA'}; //Lak;
    case CountryCode.Mng:
      return {name: 'Mongolia', languageName: 'монгол хэл', icon: 'logo-mng', currency: CurrencyType.Usd, countryCode2: 'MN'}; //Mnt;
    case CountryCode.Mmr:
      return {name: 'Myanmar', languageName: 'ဗမာစာ', icon: 'logo-mmr', currency: CurrencyType.Usd, countryCode2: 'MM'}; //Mmk;
    case CountryCode.Bgd:
      return {name: 'Bangladesh', languageName: 'বাংলা', icon: 'logo-bgd', currency: CurrencyType.Usd, countryCode2: 'BD'}; //Bdt;
    case CountryCode.Ind:
      return {name: 'India', languageName: 'हिन्दी', icon: 'logo-ind', currency: CurrencyType.Usd, countryCode2: 'IN'}; //Inr;
    case CountryCode.Mdv:
      return {name: 'Maldives', languageName: 'ދިވެހި', icon: 'logo-mdv', currency: CurrencyType.Usd, countryCode2: 'MV'}; //Mvr;
    case CountryCode.Lka:
      return {name: 'Sri Lanka', languageName: 'සිංහල', icon: 'logo-lka', currency: CurrencyType.Usd, countryCode2: 'LK'}; //Lkr;
    case CountryCode.Aut:
      return {name: 'Austria', languageName: 'Deutsch', icon: 'logo-aut', currency: CurrencyType.Eur, countryCode2: 'AT'};
    case CountryCode.Aze:
      return {name: 'Azerbaijan', languageName: 'Azərbaycanca', icon: 'logo-aze', currency: CurrencyType.Usd, countryCode2: 'AZ'}; //Azn
    case CountryCode.Bgr:
      return {name: 'Bulgaria(Rep)', languageName: 'български език', icon: 'logo-bgr', currency: CurrencyType.Usd, countryCode2: 'BG'}; //Bgn
    case CountryCode.Cyp:
      return {name: 'Cyprus', languageName: 'ελληνικά, Türkçe', icon: 'logo-cyp', currency: CurrencyType.Eur, countryCode2: 'CY'};
    case CountryCode.Cze:
      return {name: 'Czech(Rep)', languageName: 'čeština', icon: 'logo-cze', currency: CurrencyType.Usd, countryCode2: 'CZ'}; //Czk

    //스읍
    case CountryCode.Dnk:
      return {name: 'Denmark', languageName: 'Dansk', icon: 'logo-dnk', currency: CurrencyType.Dkk, countryCode2: 'DK'};
    case CountryCode.Fin:
      return {name: 'Finland', languageName: 'suomi', icon: 'logo-fin', currency: CurrencyType.Eur, countryCode2: 'FI'};
    case CountryCode.Geo:
      return {name: 'Georgia', languageName: 'ქართული', icon: 'logo-geo', currency: CurrencyType.Usd, countryCode2: 'GE'}; //Gel
    case CountryCode.Grc:
      return {name: 'Greece', languageName: 'ελληνικά', icon: 'logo-grc', currency: CurrencyType.Eur, countryCode2: 'GF'};
    case CountryCode.Hun:
      return {name: 'Hungary(Rep)', languageName: 'magyar', icon: 'logo-hun', currency: CurrencyType.Usd, countryCode2: 'HU'}; //Huf
    case CountryCode.Irn:
      return {name: 'Iran(Islamic Rep)', languageName: 'العربية, كوردی', icon: 'logo-irn', currency: CurrencyType.Usd, countryCode2: 'IR'}; //Iqd
    case CountryCode.Irl:
      return {name: 'Ireland', languageName: 'íslenska', icon: 'logo-irl', currency: CurrencyType.Usd, countryCode2: 'IE'}; //Isk
    case CountryCode.Isr:
      return {name: 'Israel', languageName: 'עברית, العربية', icon: 'logo-isr', currency: CurrencyType.Usd, countryCode2: 'IL'}; //Ils
    case CountryCode.Jor:
      return {name: 'Jordan', languageName: 'العربية', icon: 'logo-jor', currency: CurrencyType.Usd, countryCode2: 'JO'}; //Jod
    case CountryCode.Kaz:
      return {name: 'Kazakhstan', languageName: 'қазақ тілі, русский', icon: 'logo-kaz', currency: CurrencyType.Usd, countryCode2: 'KZ'}; //Kzt
    case CountryCode.Lux:
      return {
        name: 'Luxembourg',
        languageName: 'Lëtzebuergesch, Français, Deutsch',
        icon: 'logo-lux',
        currency: CurrencyType.Eur,
        countryCode2: 'LU',
      };
    case CountryCode.Nld:
      return {name: 'Netherlands', languageName: 'Nederlands', icon: 'logo-nld', currency: CurrencyType.Eur, countryCode2: 'NL'};
    case CountryCode.Nor:
      return {name: 'Norway', languageName: 'norsk', icon: 'logo-nor', currency: CurrencyType.Nok, countryCode2: 'NO'};
    case CountryCode.Omn:
      return {name: 'Oman', languageName: 'العربية', icon: 'logo-omn', currency: CurrencyType.Usd, countryCode2: 'OM'}; //Omr
    case CountryCode.Pol:
      return {name: 'Poland', languageName: 'polski', icon: 'logo-pol', currency: CurrencyType.Usd, countryCode2: 'PL'}; //Pln
    case CountryCode.Prt:
      return {name: 'Portugal', languageName: 'português', icon: 'logo-prt', currency: CurrencyType.Eur, countryCode2: 'PT'};
    case CountryCode.Qat:
      return {name: 'Qatar', languageName: 'العربية', icon: 'logo-qat', currency: CurrencyType.Usd, countryCode2: 'QA'}; //Qar
    case CountryCode.Rou:
      return {name: 'Romania', languageName: 'română', icon: 'logo-rou', currency: CurrencyType.Usd, countryCode2: 'RO'}; //Ron
    case CountryCode.Sau:
      return {name: 'Saudi Arabia', languageName: 'العربية', icon: 'logo-sau', currency: CurrencyType.Sar, countryCode2: 'SA'};
    case CountryCode.Svn:
      return {name: 'Slovenia', languageName: 'slovenščina', icon: 'logo-svn', currency: CurrencyType.Eur, countryCode2: 'SI'};
    case CountryCode.Swe:
      return {name: 'Sweden', languageName: 'svenska', icon: 'logo-swe', currency: CurrencyType.Sek, countryCode2: 'SE'};
    case CountryCode.Che:
      return {
        name: 'Switzerland',
        languageName: 'Deutsch, français, italiano, Romansh',
        icon: 'logo-che',
        currency: CurrencyType.Chf,
        countryCode2: 'CH',
      };
    case CountryCode.Tur:
      return {name: 'Turkey', languageName: 'Türkçe', icon: 'logo-tur', currency: CurrencyType.Usd, countryCode2: 'TR'}; // Try;
    case CountryCode.Ukr:
      return {name: 'Ukraine', languageName: 'українська', icon: 'logo-ukr', currency: CurrencyType.Usd, countryCode2: 'UA'}; // Uah;
    case CountryCode.Are:
      return {name: 'United Arab Emirates', languageName: 'العربية', icon: 'logo-are', currency: CurrencyType.Aed, countryCode2: 'AE'};
    case CountryCode.Uzb:
      return {name: 'Uzbekistan', languageName: 'oʻzbekcha, русский', icon: 'logo-uzb', currency: CurrencyType.Usd, countryCode2: 'UZ'}; //Uzs
    case CountryCode.Chl:
      return {name: 'Chile', languageName: 'español', icon: 'logo-chl', currency: CurrencyType.Usd, countryCode2: 'CL'}; //Clp
    case CountryCode.Egy:
      return {name: 'Egypt', languageName: 'العربية', icon: 'logo-egy', currency: CurrencyType.Usd, countryCode2: 'EG'}; //Egp
    case CountryCode.Eth:
      return {name: 'Ethiopia', languageName: 'አማርኛ', icon: 'logo-eth', currency: CurrencyType.Usd, countryCode2: 'ET'}; //Etb
    case CountryCode.Ken:
      return {name: 'Kenya', languageName: 'English, Kiswahili', icon: 'logo-ken', currency: CurrencyType.Usd, countryCode2: 'KE'}; //Kes
    case CountryCode.Mex:
      return {name: 'Mexico', languageName: 'español', icon: 'logo-mex', currency: CurrencyType.Usd, countryCode2: 'MX'}; //Mxn
    case CountryCode.Nga:
      return {name: 'Nigeria', languageName: 'English', icon: 'logo-nga', currency: CurrencyType.Usd, countryCode2: 'NG'}; //Ngn
    case CountryCode.Tza:
      return {
        name: 'Tanzania United Rep',
        languageName: 'Kiswahili, English',
        icon: 'logo-tza',
        currency: CurrencyType.Usd,
        countryCode2: 'TZ',
      }; //Tzs
    case CountryCode.Tun:
      return {name: 'Tunisia', languageName: 'العربية', icon: 'logo-tun', currency: CurrencyType.Usd, countryCode2: 'TN'}; //Tnd
    default:
      return {name: '대한민국', languageName: `한국어`, icon: 'logo-korea', currency: CurrencyType.Krw, countryCode2: 'KR'};
  }
};

export const replaceLanguageCodeToText = (languageCode: LanguageCode): string => {
  switch (languageCode) {
    case LanguageCode.Ko:
      return '한국어';
    case LanguageCode.En:
      return '영어';
    case LanguageCode.Ja:
      return '일본어';
    case LanguageCode.De:
      return '독일어';
    case LanguageCode.Es:
      return '스페인어';
    case LanguageCode.Id:
      return '인도어';
    case LanguageCode.ZhChs:
      return '중국어';
    case LanguageCode.Th:
      return '태국어';

    case LanguageCode.Fr:
      return '프랑스어';
    case LanguageCode.Br:
      return '브라질어';
    case LanguageCode.ZhCht:
      return '홍콩/대만어';
    case LanguageCode.My:
      return '말레이시아어';
    case LanguageCode.Vn:
      return '베트남어';
    case LanguageCode.Ru:
      return '러시아어';

    case LanguageCode.Oo:
      return '기본값';
    default:
      return '';
  }
};

export const replacedUrl = (countryCode: CountryCode, pathname: string, search: string) => {
  const path = pathname + search;

  switch (countryCode) {
    case CountryCode.Kor:
      return `${configs.urls.rootKr}${path}`;
    case CountryCode.Usa:
      return `${configs.urls.rootEn}${path}`;
    case CountryCode.Jpn:
      return `${configs.urls.rootJp}${path}`;
    case CountryCode.Chn:
      return `${configs.urls.rootCn}${path}`;
    case CountryCode.Esp:
      return `${configs.urls.rootEs}${path}`;
    case CountryCode.Deu:
      return `${configs.urls.rootDe}${path}`;
    case CountryCode.Idn:
      return `${configs.urls.rootId}${path}`;
    case CountryCode.Tha:
      return `${configs.urls.rootTh}${path}`;
    case CountryCode.Fra:
      return `${configs.urls.rootFr}${path}`;
    case CountryCode.Bra:
      return `${configs.urls.rootBr}${path}`;
    case CountryCode.Aus:
      return `${configs.urls.rootAu}${path}`;
    case CountryCode.Can:
      return `${configs.urls.rootCa}${path}`;
    case CountryCode.Hkg:
      return `${configs.urls.rootHk}${path}`;
    case CountryCode.Mys:
      return `${configs.urls.rootMy}${path}`;
    case CountryCode.Nzl:
      return `${configs.urls.rootNz}${path}`;
    case CountryCode.Phl:
      return `${configs.urls.rootPh}${path}`;
    case CountryCode.Sgp:
      return `${configs.urls.rootSg}${path}`;
    case CountryCode.Twn:
      return `${configs.urls.rootTw}${path}`;
    case CountryCode.Gbr:
      return `${configs.urls.rootGb}${path}`;
    case CountryCode.Vnm:
      return `${configs.urls.rootVn}${path}`;
    case CountryCode.Rus:
      return `${configs.urls.rootRu}${path}`;

    default:
      return `${configs.urls.urlRoot}${path}`;
  }
};

export const currentIsGlobal = () => !(currentHost() === HostCode.Kr || currentHost() === HostCode.DKr);
