import {configs} from 'Configs';

const urlSearchParams = new window.URLSearchParams(window.location.search);
const fallbackLink = urlSearchParams.get('link');
const androidPackageName = urlSearchParams.get('apn');
const iosBundleIdentifier = urlSearchParams.get('ibi');
const iosStoreIdentifier = urlSearchParams.get('isi');
const androidLink = urlSearchParams.get('al');
const isDynamicLink = fallbackLink && androidPackageName && iosBundleIdentifier && iosStoreIdentifier && androidLink;
if (isDynamicLink) {
  window.location.href = `${configs.deepLink.base}?${window.location.search}`;
}

const isDeepLink = urlSearchParams.has('deeplink');
if (isDeepLink) {
  const scheme = `${configs.deepLink.scheme}${window.location.pathname}`.replace(/\/{3,}/g, '//');
  const urlSearchParams = new window.URLSearchParams({
    link: window.location.origin + window.location.pathname,
    apn: configs.deepLink.apn,
    ibi: configs.deepLink.ibi,
    isi: configs.deepLink.isi,
    al: scheme,
    ius: scheme,
  });
  window.location.href = `${configs.deepLink.base}?${urlSearchParams}`;
}
