import * as SentryReact from '@sentry/react';
import {SeverityLevel} from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {configs} from 'Configs';

const init = () =>
  SentryReact.init({
    dsn: configs.sentry.dsn,
    integrations: [new SentryReact.Integrations.Breadcrumbs({console: true}), new BrowserTracing()],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1.0,
    normalizeDepth: 6,
    denyUrls: ['localhost', 'staging'],
  });
const captureMessage = (message: string, severityLevel?: SeverityLevel) => SentryReact.captureMessage(message, severityLevel);
const captureException = (exception: any) => SentryReact.captureException(exception);
const setUser = (user: SentryReact.User) =>
  SentryReact.configureScope((scope) => {
    scope.setUser(user);
  });

export const Sentry = {init, captureMessage, captureException, setUser};
